import { CaseStudyTemplateProps, CaseStudyTemplate } from "@src/components/CaseTemplete";
import React from "react";
import * as UseCase from "@src/components/partials/districts-2025/UseCaseProvider";
import SpringIcon from "@src/www/districts/_assets/icon-confetti.png";
import SpringLogo from "@src/www/districts/_assets/spring-isd@2x.png";
import SpringHeadshot from "@src/www/districts/_assets/spring-headshot.jpg";
import SpringPhoto from "@src/www/districts/_assets/spring-isd.jpg";
import getLocale from "@src/utils/getLocale";

const SprinIdsPage = ({ pageContext }) => {
  const c = UseCase.useGetCase(1);

  const userLanguage = getLocale(pageContext);
  const noIndex = ["en", "en-us"].includes(userLanguage) ? false : true;

  const springISD: CaseStudyTemplateProps = {
    id: "spring",
    shouldIndex: noIndex,
    hero: {
      image: SpringPhoto,
      title: c.title,
      logo: SpringLogo,
    },
    person: {
      image: SpringHeadshot,
      name: c.quote_person_name,
      title: c.quote_person_title,
      quote: c.quote,
    },
    content: c.content,
    summary: [
      {
        text: c.stat_1_text,
        value: c.stat_1_value,
      },
      {
        text: c.stat_2_text,
        value: c.stat_2_value,
        icon: SpringIcon,
      },
      {
        text: c.stat_3_text,
        value: c.stat_3_value,
      },
    ],
  };

  return <CaseStudyTemplate {...springISD} />;
};

export default UseCase.withProvider(SprinIdsPage);
